import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Empty } from '@h3/antd-vue';
import Perm from '@/router/permission';
import PermCode from '@/router/perm-code';
import { Loading } from '@cloudpivot-hermes/common-components';
import { namespace } from 'vuex-class';
const OrganizationModule = namespace('Organization');
const organization = PermCode.organization;
let Organization = class Organization extends Vue {
    constructor() {
        super(...arguments);
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
        this.isReloadRouter = true;
        this.loading = false;
        this.timer = 0;
        this.menus = [
            {
                name: '平台账号',
                code: organization.inServiceStaff,
                routeName: 'in-service-staff',
                icon: 'h-icon-all-team-o',
                children: [],
            },
            {
                name: '组织架构管理',
                code: organization.organizationManage,
                routeName: 'organization-manage',
                icon: 'h-icon-all-department-o',
                children: [],
            },
            {
                name: '标签管理',
                code: organization.labelManage,
                routeName: 'label-manage',
                icon: 'h-icon-all-tag-o',
                children: [
                    {
                        name: '通用标签',
                        code: organization.labels,
                        routeName: 'labels',
                        icon: 'h-icon-all-tag-o',
                        children: [],
                    },
                    {
                        name: '组织专用标签',
                        code: organization.specialUseLabel,
                        routeName: 'special-use-label',
                        icon: 'h-icon-all-tag-o',
                        children: [],
                    },
                ],
            },
        ];
    }
    get siderMenus() {
        return Perm.filterMenus(this.menus);
    }
    onCheckOrgLoadingChange() {
        if (this.checkOrgLoading) {
            this.timer = setTimeout(() => {
                this.loading = this.checkOrgLoading;
            }, 1000);
        }
        else {
            clearTimeout(this.timer);
        }
    }
    async reload() {
        this.isReloadRouter = false;
        await this.$nextTick();
        this.isReloadRouter = true;
    }
};
__decorate([
    OrganizationModule.State('orgCount')
], Organization.prototype, "orgCount", void 0);
__decorate([
    OrganizationModule.State('checkOrgLoading')
], Organization.prototype, "checkOrgLoading", void 0);
__decorate([
    Watch('checkOrgLoading', { immediate: true })
], Organization.prototype, "onCheckOrgLoadingChange", null);
Organization = __decorate([
    Component({
        name: 'Organization',
        components: {
            Sider: () => import('@/components/global/sider/index.vue'),
            Loading,
        },
    })
], Organization);
export default Organization;
